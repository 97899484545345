<template>
  <div class="w-100 h-100 d-flex flex-column justify-space-between">
    <steps-counter :current-step="4" />

    <div class="text-center">
      <h1 class="font-22 font-700">Create Your First Group</h1>
      <p class="font-18">Organize related content.</p>

      <form class="w-100">
        <v-row>
          <v-col cols="12">
            <label
              for="title"
              class="input-group font-18 w-100 d-flex gap-5 text-start"
            >
              <span class="font-600 no-wrap">Group Name: </span>
              <input id="title" type="text" class="w-100" v-model="form.name" />
            </label>
          </v-col>
        </v-row>
      </form>
    </div>

    <div>
      <p class="font-16 font-500 text_grey text-center">Modify or use sample</p>
      <v-btn
        @click="submitHandler"
        :loading="loading"
        class="primary next-btn w-100"
        elevation="2"
        large
      >
        Next
      </v-btn>
    </div>
  </div>
</template>

<script>
import StepsCounter from "../StepsCounter.vue";
export default {
  components: {
    StepsCounter,
  },
  data() {
    return {
      loading: false,
      form: {
        name: "Summer collection",
        themeId: "661559602495c924dafe0a9b",
      },
    };
  },
  methods: {
    async submitHandler() {
      this.loading = true;
      if (!this.form.name) {
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: "Please write a group name",
          color: "error",
        });

        return;
      }

      let res = await this.$store.dispatch(`offersModule/saveGroup`, this.form);

      if (res.data?.success || res.success) {
        this.loading = false;
        console.log(res);
        this.$emit("next", res.data.group._id);
      } else {
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: res.message || res.error || "Something went wrong!",
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.input-group {
  background-color: rgba(244, 244, 244, 1);
  border-radius: 15px;
  padding: 9px 15px;
}
</style>
